export const commonEnvironment = {
  /** The key to access the storage and get the user data */
  currentUserKey: 'k-currentUser',

  /** The key to access storage and get the token */
  currentUserTokenKey: 'k-currentUserToken',

  productionLot: 'k-productionLot',

  /** unsaved on db id value */
  unsavedId: -1,

  /** default culture for the app */
  defaultCulture: 'en',

  uokRoleContributorFirst: 'Uok.Role.Contributor.First',

  /** Character used to split a string in to a set of items */
  splitChar: ',',

  /** max bytes for an image */
  maxImageFileBytes: 5242880,

  /** max bytes for a document */
  maxDocumentFileBytes: 10485760,

  lnkExternalUrlSystemName: 'lnk:[Uok.Unt].WebLinks.[URL]',

  maxSearchItems: 20,

  production: false,
  environmentName: 'test',
  webApi: 'https://localhost/api/',
  webApiV1: 'https://localhost/api/v1.0.0/',
  webApp: 'http://localhost:4200/#/',
  vimeoAccessToken: '7c66b233bbdd12a98af308e2a2dbdf7f', // vimeo test Emanuele
  // vimeoAccessToken: '4ae00d53b1b221ea3e7e9eb0e3e73fc8', // vimeo test Roberto
  hrefPrefixInTextReplace: '#',

  stripe: {
    key: 'pk_test_XOzU5EwnSj0vUyVgPE9uYZHS', //  Official Stripe Account (administration@knowlative.com)
    returnUrlOnConfirmation: 'http://localhost:49152/', //redirect website to pass the parameters to the signup
    defaultPlanPrice: 24, //in eur used to setup the stripe payment element component
    paymentMethodTypes: ['card'], // stripe payment element default payment methods
  },

  matomo: {
    trackerUrl: 'https://k-develop.knowlative.com/matomo',
    siteId: '1',
  },

  services: {
    authRepository: 'api', // api | mock
    userRepository: 'api', // api | mock
    accessKeyRepository: 'api',
    activityCenter: 'app', // app | mock
    translateConfig: 'app', // app | mock
    productRepository: 'api',
    surveyRepository: 'api',
    searchEngineRepository: 'api',
    uokDetailRepository: 'api',
    uokLinkRepository: 'api',
    draftRepository: 'api',
    stripeRepository: 'api',
    utilityRepository: 'api',
  },

  useCases: {
    auth: {
      login: 'app', // app | mock
    },
    products: 'app',
    survey: 'app',
    searchEngine: 'app',
    uokDetail: 'app',
    draft: 'app',
    stripe: {
      createSubscription: 'app',
      confirmPayment: 'app',
      getSetupIntent: 'app',
      getCustomer: 'app',
      confirmSetupIntent: 'app',
    },
    utility: {
      cultureDefinitions: 'app',
    }
  },

  turnstileToken: '0x4AAAAAAAdkJnCJKLXsXqMy',

  appRevision: '20250124A',
};
