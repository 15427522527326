import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { AccountRepository } from '../../core/repositories';
import { AccountSignOutActions } from '../../core/state/account';
import { UseCasesActions } from '../../core/state/use-cases';
import { UserActions } from '../../core/state/user/actions';
import { AccountSignOutUseCase } from '../../core/use-cases/account';
import { RepositoryFactory } from '../../data';
import { UseCaseAppBase } from '../use-case-app-base.class';
import { ProductActions } from '../../core/state/product';
import { SearchEngineActions } from '../../core/state/search-engine';
import { SurveyActions } from '../../core/state/survey';
import { HistoryStateActions } from '../../core/state/history';
import { Router } from '@angular/router';
import { DraftStateIndexeddb } from '../../core/state/draft-state/draft-state-indexeddb';
import { ResetMenuItems } from '../../core/state/main-menu/main-menu-actions.class';
import { MatomoTracker } from 'ngx-matomo-client';
import { UseCaseStateName } from '../../core/utils';

export class AccountSignOutUseCaseApp extends UseCaseAppBase<null> implements AccountSignOutUseCase {
  public static readonly Name: string = 'AccountSignOut';
  private authRepository: AccountRepository;

  constructor(store: Store, private router: Router, private draftState: DraftStateIndexeddb, private tracker: MatomoTracker) {
    super(store);

    this.authRepository = RepositoryFactory.GetSecurityRepository();
  }

  override init(): void {
    this.dispatchSetStateWithNone(UseCaseStateName.initialized, 'UseCase.LinkEditorLoadUok.Initialized');
  }

  override async onExecuteRun(params?: null) {
    const ret = this.router.routerState.snapshot.url;
    await this.draftState.ClearDraft();
    this.tracker.resetUserId();

    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.LinkEditorLoadUok.Working');

    this.dispatch([
      new UserActions.ClearModelSucceded(),
      new AccountSignOutActions.Succeded(),
      new UseCasesActions.Reset(),
      // new RemoveDraftMenuItem(),
      // new RemoveUokMenuItem(),
      new ProductActions.ResetProducts(),
      new SearchEngineActions.ResetSearchEngine(),
      new SurveyActions.SurveyClearState(),
      // new DraftStateActions.ResetDrafts(),
      new HistoryStateActions.ResetHistory(),
      new ResetMenuItems()
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.authRepository.logout();
      });

    this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.LinkEditorLoadUok.Success');
    this.router.navigate(['signin'], { queryParams: { returnUrl: ret } }).then();
    // this.dispatch(
    //   new StateClear()
    // );
  }

  override getName(): string {
    return AccountSignOutUseCaseApp.Name;
  }

  override onDispose() {
    this.authRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.LinkEditorLoadUok.Disposed');
  }
}
