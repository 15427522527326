import {Navigate} from '@ngxs/router-plugin';
import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {IDtoUserOperator} from '../../api/dtos/user';
import {AccountRepository, StripeRepository, UserRepository} from '../../core/repositories';
import {TranslateConfig} from '../../core/services/translate-config.class';
import {AccountAddUserPermissions, AccountSignInActions} from '../../core/state/account';
import {UseCasesActions} from '../../core/state/use-cases';
import {UserActions, UserStripeInfoActions} from '../../core/state/user/actions';
import {AccountSignInUseCase} from '../../core/use-cases/account';
import {DtoUtils, UseCaseStateName} from '../../core/utils';
import {RepositoryFactory} from '../../data';
import {AuthMapper} from '../../data/auth-repository';
import {StripeInfoMapper, UserMapper} from '../../data/user-repository';
import {UseCaseAppBase} from '../use-case-app-base.class';
import {AppRoutes} from '../../app-routes';
import {MatomoTracker} from "ngx-matomo-client";
import {Observable} from 'rxjs';
import {RepositoryResponse} from '../../core/abstractions/repositories';
import {IDtoStripeData} from '../../api/dtos/stripe';
import { UserState } from '../../core/state/user';
import { environment } from '../../../environments/environment';

export class AccountSignInUseCaseApp extends UseCaseAppBase<{
  username: string,
  password: string,
  captchaToken: string
}> implements AccountSignInUseCase {
  public static readonly Name: string = 'AccountSignIn';

  private authMapper: AuthMapper = new AuthMapper();
  private userMapper: UserMapper = new UserMapper();
  private stripeMapper = new StripeInfoMapper();

  private authRepository: AccountRepository;
  private userRepository: UserRepository;
  private stripeRepository: StripeRepository;
  private userStripe$: Observable<RepositoryResponse<IDtoStripeData>>;
  private user$: Observable<RepositoryResponse<IDtoUserOperator>>;
  private user: IDtoUserOperator | undefined = undefined;

  constructor(store: Store, private translateConfig: TranslateConfig, private matomoTracker: MatomoTracker) {
    super(store);

    this.authRepository = RepositoryFactory.GetSecurityRepository();
    this.userRepository = RepositoryFactory.GetUserRepository();
    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.user$ = this.userRepository.userStateResponse$();
    this.userStripe$ = this.stripeRepository.userStripeInfoResponse$();

    this.setupObservables();
  }

  private setupObservables() {
    this.authRepository.loginAuthStateResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.AccountSignIn.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.AccountSignIn.Failure.Header"},
              {localizationKey: "UseCase.AccountSignIn.Failure.SubHeader"});

          } else {
            this.dispatch(new AccountSignInActions.Succeded({authModel: this.authMapper.mapFrom(response.dto)}));
            if (response.dto.userName !== null) {
              this.doUserGet(response.dto.userName)
            }
          }
        }
      });

    // combineLatest([this.user$, this.userStripe$])
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(([userResponse, stripeResponse]) => {
    //     if (!(stripeResponse.dto === null || stripeResponse.dto === undefined)) {
    //       this.dispatch(new UserStripeInfoActions.Succeded(this.stripeMapper.mapFrom(stripeResponse.dto)));
    //     }
    //
    //     if (userResponse.dto === null || userResponse.dto === undefined) {
    //       this.dispatch(new UserActions.GetModelFailed());
    //
    //       let msg = '';
    //       userResponse.info?.messages.forEach(m => msg += m + ', ');
    //       this.dispatchSetFailureWithAlert(
    //         {localizationKey: "UseCase.AccountSignIn.Failure.Message", params: msg},
    //         {localizationKey: "UseCase.AccountSignIn.Failure.Header"},
    //         {localizationKey: "UseCase.AccountSignIn.Failure.SubHeader"});
    //     } else {
    //       // this.matomoTracker.setUserId(response.dto.DbId.toString() ??'UNDEFINED-USER');
    //       this.dispatch(new UserActions.GetModelSucceded(this.userMapper.mapFrom(userResponse.dto)))
    //       this.dispatch(new AccountAddUserPermissions.Succeded(this.getPermissionsFromDto(userResponse.dto)))
    //       this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.AccountSignIn.Success");
    //       this.translateConfig.getCurrentLang();
    //       this.dispatch(new Navigate([AppRoutes.dashboard]))
    //     }
    //   });

    this.userStripe$.pipe(takeUntil(this.destroy$))
      .subscribe(stripeResponse => {
        if (!(stripeResponse.dto === null || stripeResponse.dto === undefined)) {
          this.dispatch(new UserStripeInfoActions.Succeded(this.stripeMapper.mapFrom(stripeResponse.dto)));
        }
        this.dispatch(new UserActions.GetModelSucceded(this.userMapper.mapFrom(this.user!)))
        this.dispatch(new AccountAddUserPermissions.Succeded(this.getPermissionsFromDto(this.user!)))
        this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.AccountSignIn.Success");

        this.translateConfig.getCurrentLang();
        this.dispatch(new Navigate([AppRoutes.dashboard]))
      })

    this.user$
      .pipe(takeUntil(this.destroy$))
      .subscribe(userResponse => {
        if (userResponse.dto === null || userResponse.dto === undefined) {
          this.dispatch(new UserActions.GetModelFailed());

          let msg = '';
          userResponse.info?.messages.forEach(m => msg += m + ', ');
          this.dispatchSetFailureWithAlert(
            {localizationKey: "UseCase.AccountSignIn.Failure.Message", params: msg},
            {localizationKey: "UseCase.AccountSignIn.Failure.Header"},
            {localizationKey: "UseCase.AccountSignIn.Failure.SubHeader"});
        } else {
          this.user = userResponse.dto;
          this.stripeRepository.getUserStripeInfo(this.selectSnapshot(UserState.user)?.cultureName ?? environment.defaultCulture);
          this.matomoTracker.setUserId(this.user.DbId.toString());
          // this.dispatch(new UserActions.GetModelSucceded(this.userMapper.mapFrom(userResponse.dto)))
          // this.dispatch(new AccountAddUserPermissions.Succeded(this.getPermissionsFromDto(userResponse.dto)))
          // this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.AccountSignIn.Success");
          // this.translateConfig.getCurrentLang();
          // this.dispatch(new Navigate([AppRoutes.dashboard]))
        }
      });

  }

  private getPermissionsFromDto(dto: IDtoUserOperator): string[] {
    const permissions: string[] = [];
    dto.Groups.forEach(g => {
      permissions.push(g.Name);
    })
    permissions.push(dto.IsLicenceFree ? 'User.Free' : 'User.Pro');
    return permissions;
  }

  override getName(): string {
    return AccountSignInUseCaseApp.Name;
  }

  private doUserGet(username: string): void {
    this.dispatch(new UserActions.GetModelRequested(username));
    this.userRepository.getUserStateModel(true);
  }

  init(): void {
    this.dispatch(new UseCasesActions.Reset());
    this.dispatchInitWithMessage("UseCase.AccountSignIn.Initialized");
  }

  override onExecuteUserEdit() {
    this.dispatchSetStateWithNone(UseCaseStateName.edit, "UseCase.AccountSignIn.Edit");
  }

  override onExecuteUserSave(params?: { username: string, password: string, captchaToken: string }) {
    if (params === undefined) {
      return;
    }
    this.dispatchSetStateWithNone(UseCaseStateName.working, "UseCase.AccountSignIn.Working");
    this.dispatch(new AccountSignInActions.Requested(params));
    this.authRepository.login(params.username, params.password, params.captchaToken);
  }

  override onDispose() {
    this.authRepository.dispose();
    this.userRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.AccountChangePassword.Disposed');
  }
}
