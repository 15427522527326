import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {AccountRepository} from '../../core/repositories';
import {AccountChangePasswordUseCase} from '../../core/use-cases/account';
import {DtoUtils, UseCaseStateName} from '../../core/utils';
import {RepositoryFactory} from '../../data';
import {UseCaseAppBase} from '../use-case-app-base.class';
import {MessageActivityLevel} from "../../core/utils/message-activity-level.enum";

export class AccountChangePasswordUseCaseApp extends UseCaseAppBase<{
  oldPassword: string,
  password: string,
  confirmPassword: string
}> implements AccountChangePasswordUseCase {
  public static readonly Name: string = "AccountChangePassword";
  private authRepository: AccountRepository;

  constructor(store: Store) {
    super(store);
    this.authRepository = RepositoryFactory.GetSecurityRepository();

    this.authRepository.changePasswordResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.info?.level === 1) {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.AccountChangePassword.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.AccountChangePassword.Failure.Header"},
              {localizationKey: "UseCase.AccountChangePassword.Failure.SubHeader"});

          } else {
            this.dispatchSetStateWithToast(
              UseCaseStateName.success,
              "UseCase.AccountChangePassword.Success",
              undefined,
              undefined,
              MessageActivityLevel.success)
          }
        }
      });
  }

  override getName(): string {
    return AccountChangePasswordUseCaseApp.Name;
  }

  override init(): void {
    this.dispatchInitWithMessage("UseCase.AccountChangePassword.Initialized");
  }

  override onExecuteRun(params: { oldPassword: string, password: string, confirmPassword: string }) {
    this.dispatchSetStateWithNone(UseCaseStateName.working, "UseCase.AccountChangePassword.Working");
    this.authRepository.changePassword({
      oldPassword: params.oldPassword,
      newPassword: params.password,
      confirmPassword: params.confirmPassword
    });
  }

  override onDispose() {
    this.authRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.AccountChangePassword.Disposed');
  }
}
