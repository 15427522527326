import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { MenuItemCustom, MenuRoot } from '../../../presentation/ui-kit/layout/models';
import {
  RemoveDraftMenuItem, RemoveLinkEditorMenuItem,
  RemoveUokMenuItem, LastMenuItem,
  SetDraftMenuItem, SetLinkEditorMenuItem,
  SetMenuExpand,
  SetShowMobileMenu,
  SetToggleMenu, SetUokMenuItem,
  UpdateActiveMenu, ResetMenuItems,
} from './main-menu-actions.class';
import { MainMenuModel } from './main-menu-model.interface';
import { AppRoutes } from '../../../app-routes';

const defaults: MainMenuModel = {
  showMobileMenu: false,
  showSidebar: false,
  menuRoot: [
    {
      menuItemChild: {
        iconName: 'squares',
        labelKey: 'Cmp.MainMenu.Items.Dashboard.Label',
        route: AppRoutes.dashboard,
        children: [],
      },
    },
    {
      menuItemChild: {
        iconName: 'building-library',
        labelKey: 'Cmp.MainMenu.Items.Knowledge.Label',
        route: AppRoutes.knowledge,
        children: [],
      },
    },
    {
      menuItemChild: {
        iconName: 'bookmark-outline',
        labelKey: 'Cmp.MainMenu.Items.Bookmarks.Label',
        route: AppRoutes.bookmarks,
        children: [],
      },
    },
    {
      menuItemChild: {
        iconName: 'pencil-square',
        labelKey: 'Cmp.MainMenu.Items.MyDrafts.Label',
        route: AppRoutes.myDrafts,
        children: [],
      },
    },
    {
      menuItemChild: {
        iconName: 'book-open',
        labelKey: 'Cmp.MainMenu.Items.Library.Label',
        route: AppRoutes.library,
        children: [],
      },
    },
  ],
};

@State<MainMenuModel>({
  name: 'mainMenu',
  defaults: defaults,
})
@Injectable()
export class MainMenuState {
  constructor(private router: Router) {
  }

  @Selector()
  static menuRoot(state: MainMenuModel): MenuRoot[] {
    return state.menuRoot;
  }

  @Selector()
  static showMobileMenu(state: MainMenuModel): boolean {
    return state.showMobileMenu;
  }

  @Selector()
  static showSidebar(state: MainMenuModel): boolean {
    return state.showSidebar;
  }

  @Action(SetShowMobileMenu)
  setShowMobileMenu(ctx: StateContext<MainMenuModel>, action: SetShowMobileMenu): void {
    ctx.patchState({ showMobileMenu: action.showMobileMenu });
  }

  @Action(ResetMenuItems)
  resetMenuItems(ctx: StateContext<MainMenuModel>): void {
    ctx.patchState(defaults);
  }

  @Action(SetToggleMenu)
  setToggleMenu(ctx: StateContext<MainMenuModel>, action: SetToggleMenu): void {
    action.menu.expanded = !action.menu.expanded;
    const serializedMenu = JSON.stringify(ctx.getState().menuRoot);
    const clone: Array<MenuRoot> = JSON.parse(serializedMenu);

    ctx.patchState({
      showSidebar: true,
      menuRoot: clone,
    });
  }

  @Action(SetMenuExpand)
  private expand(ctx: StateContext<MainMenuModel>, action: SetMenuExpand) {
    action.items.forEach((item) => {
      item.expanded = this.isActive(item.route);
      if (item.children) this.expand(ctx, item.children);
    });
  }

  @Action(SetUokMenuItem)
  setUokMenuItem(ctx: StateContext<MainMenuModel>, action: SetUokMenuItem) {
    this.setMenuItemById(ctx, action.item, 'uok-menu-item');
  }

  @Action(RemoveUokMenuItem)
  removeUokMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'uok-menu-item');
  }

  @Action(SetDraftMenuItem)
  setDraftMenuItem(ctx: StateContext<MainMenuModel>, action: SetDraftMenuItem) {
    this.setMenuItemById(ctx, action.item, 'draft-menu-item');
  }

  @Action(RemoveDraftMenuItem)
  removeDraftMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'draft-menu-item');
  }

  @Action(SetLinkEditorMenuItem)
  setLinkEditorMenuItem(ctx: StateContext<MainMenuModel>, action: SetDraftMenuItem) {
    this.setMenuItemById(ctx, action.item, 'link-editor-menu-item');
  }

  @Action(RemoveLinkEditorMenuItem)
  removeLinkEditorMenuItem(ctx: StateContext<MainMenuModel>): void {
    this.removeMenuItemById(ctx, 'link-editor-menu-item');
  }

  private removeMenuItemById(ctx: StateContext<MainMenuModel>, id: string): void {
    const root = ctx.getState().menuRoot;
    const navbarItemIndex = root.findIndex(x => x.customMenuItem?.customMenuId === 'custom-menu-1') ?? -1;

    if (navbarItemIndex === -1) {
      return;
    }

    const navbarItem = root[navbarItemIndex].customMenuItem;

    if (!navbarItem) {
      return;
    }

    // If we need to eliminate a children just remove it, update the root and exit
    if (navbarItem.root.id !== id) {
      const childIndex = navbarItem.children.findIndex(x => x.id === id);
      navbarItem.children.splice(childIndex, 1);
      root.splice(navbarItemIndex, 1, { customMenuItem: navbarItem });
      return;
    }

    // look in the root, if we have a children put it as root else remove the menu entirely
    if (navbarItem.children.length > 0) {
      // take the first child to be the root and remove it from the children array
      navbarItem.root = navbarItem.children[0];
      navbarItem.children.splice(0, 1);
      root.splice(navbarItemIndex, 1, { customMenuItem: navbarItem });
    } else {
      root.splice(navbarItemIndex, 1);
    }
  }

  private setMenuItemById(ctx: StateContext<MainMenuModel>, newNavbarItem: LastMenuItem, id: string) {
    const root = ctx.getState().menuRoot;
    const navbarItemIndex = root.findIndex(x => x.customMenuItem?.customMenuId === 'custom-menu-1') ?? -1;

    if (!newNavbarItem) {
      return;
    }

    const tmp: MenuItemCustom = {
      customMenuId: 'custom-menu-1',
      root: {
        id: id,
        labelKey: newNavbarItem.label,
        route: newNavbarItem.path,
        iconName: newNavbarItem.icon,
        active: true,
        expanded: false,
        children: [],
      },
      children: [],
    };

    // if there is no existing navbar item add the one newly created and exit
    if (navbarItemIndex === -1) {
      root.push({
        customMenuItem: tmp,
      });

      return;
    }

    // retrieve the existing navbar item and move the entries to tmp, skipping the one with the root id
    const navbarItem = root[navbarItemIndex].customMenuItem!;
    navbarItem.children.forEach(c => {
      if (c.id !== tmp.root.id) {
        tmp.children.push(c);
      }
    });

    // merge the existing navbar item with the newly created and update the root
    if (navbarItem.root.id !== tmp.root.id) {
      const i = navbarItem.children.findIndex(x => x.id === navbarItem.root.id);
      if (i === -1) {
        tmp.children.push(navbarItem.root);
      } else {
        tmp.children.splice(i, 1, navbarItem.root);
      }
    }

    root.splice(navbarItemIndex, 1, { customMenuItem: tmp });
  }


  @Action(UpdateActiveMenu)
  private updateActiveMenu(ctx: StateContext<MainMenuModel>) {
    const root = ctx.getState().menuRoot;
    root.forEach((menuItem) => {
      let activeGroup = false;
      if (menuItem.menuItem) {
        menuItem.menuItem.children?.forEach((childMenu) => {
          const active = this.isActive(childMenu.route);
          childMenu.expanded = active;
          childMenu.active = active;
          if (active) {
            activeGroup = true;
          }
          if (childMenu.children) {
            this.expand(ctx, { items: childMenu.children });
          }

        });
        menuItem.menuItem.active = activeGroup;
      } else if (menuItem.menuItemChild) {
        // menuItem.menuItemChild.active = this.isActive(menuItem.menuItemChild.route);
        const item = root.find(x => x.menuItemChild?.route === menuItem.menuItemChild?.route);

        if (item && item.menuItemChild) {
          item.menuItemChild.active = this.isActive(menuItem.menuItemChild.route);
        }
      } else if (menuItem.customMenuItem) {
        // menuItem.menuItemChild.active = this.isActive(menuItem.menuItemChild.route);
        const item = root.find(x => x.customMenuItem?.root.route === menuItem.customMenuItem?.root.route);

        if (item && item.customMenuItem) {
          item.customMenuItem.root.active = this.isActive(menuItem.customMenuItem.root.route);
        }
      }
    });

    ctx.patchState({
      menuRoot: root,
    });
  }

  private isActive(instruction: any): boolean {
    // console.log("TREE: " + this.router.createUrlTree([instruction]), {
    //   paths: 'subset',
    //   queryParams: 'subset',
    //   fragment: 'ignored',
    //   matrixParams: 'ignored',
    // });

    // console.log({
    //   route: instruction,
    //   res: a
    // })

    return this.router.isActive(this.router.createUrlTree([instruction]), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
