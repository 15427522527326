import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';
import {IDtoUserOperator} from '../../api/dtos/user';
import {UserRepository} from '../../core/repositories';
import {AccountAddUserPermissions} from '../../core/state/account';
import {UserActions} from '../../core/state/user/actions';
import {UserGetUseCase} from '../../core/use-cases/user';
import {DtoUtils, UseCaseStateName} from '../../core/utils';
import {RepositoryFactory} from '../../data';
import {UserMapper} from '../../data/user-repository';
import {UseCaseAppBase} from '../use-case-app-base.class';
import {TranslateConfig} from "../../core/services/translate-config.class";

export class UserGetUseCaseApp extends UseCaseAppBase<{ updateLogin: boolean }> implements UserGetUseCase {
  public static readonly Name: string = 'UserGet';
  private mapper = new UserMapper();
  private userRepository: UserRepository;

  constructor(store: Store, translate: TranslateConfig) {
    super(store);

    this.userRepository = RepositoryFactory.GetUserRepository();

    this.userRepository.userStateResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {localizationKey: "UseCase.UserGet.Failure.Message", params: DtoUtils.getMessage(response)},
              {localizationKey: "UseCase.UserGet.Failure.Header"},
              {localizationKey: "UseCase.UserGet.Failure.SubHeader"});
          } else {
            this.dispatch(new UserActions.GetModelSucceded(this.mapper.mapFrom(response.dto)));
            translate.setLanguage(response.dto.CultureName);
            this.dispatch(new AccountAddUserPermissions.Succeded(this.getPermissionsFromDto(response.dto)))
            this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.UserGet.Success");
          }
        }
      });
  }

  override init() {
    this.dispatchInitWithMessage("UseCase.UserGet.Initialized");
  }

  override onExecuteRun(params?: { updateLogin: boolean }) {
    this.dispatchSetStateWithNone(UseCaseStateName.edit, "UseCase.UserGet.Working");
    this.userRepository.getUserStateModel(params?.updateLogin ?? false);
  }

  override getName(): string {
    return UserGetUseCaseApp.Name;
  }

  override onDispose() {
    this.userRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.UserGet.Disposed');
  }

  private getPermissionsFromDto(dto: IDtoUserOperator): string[] {
    const permissions: string[] = [];
    dto.Groups.forEach(g => {
      permissions.push(g.Name);
    })
    permissions.push(dto.IsLicenceFree ? 'User.Free' : 'User.Pro');
    return permissions;
  }
}
