import { UseCaseAppBase } from '../use-case-app-base.class';
import { GetCultureDefinitionsUseCase } from '../../core/use-cases/utility/get-culture-definitions-use-case.interface';
import { RepositoryFactory } from '../../data';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { DtoUtils, UseCaseStateName } from '../../core/utils';
import { CultureDefinitionsLoaded } from '../../core/state/utility/utility-actions';

export class GetCultureDefinitionsUseCaseApp extends UseCaseAppBase<null> implements GetCultureDefinitionsUseCase {
  public static Name = 'GetCultureDefinitions';

  public override getName(): string {
    return GetCultureDefinitionsUseCaseApp.Name;
  }

  private utilityRepository = RepositoryFactory.GetUtilityRepository();

  constructor(store: Store) {
    super(store);

    this.utilityRepository.cultureDefinitionsResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {
                localizationKey: 'UseCase.GetCultureDefinitions.Failure.Message',
                params: DtoUtils.getMessage(response),
              },
              { localizationKey: 'UseCase.GetCultureDefinitions.Failure.Header' },
              { localizationKey: 'UseCase.GetCultureDefinitions.Failure.SubHeader' });
          } else {
            this.dispatch(new CultureDefinitionsLoaded(response.dto));
            this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.GetCultureDefinitions.Success');
          }
        },
      });
  }

  override onExecuteRun() {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.GetCultureDefinitions.Working');
    this.utilityRepository.loadCultureDefinitions();
  }

  init(): void {
    this.dispatchInitWithMessage('UseCase.GetCultureDefinitions.Initialized');
  }

  override onDispose() {
    this.utilityRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.GetCultureDefinitions.Disposed');
  }
}
