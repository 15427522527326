import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripeRepository } from '../../core/repositories';
import { Store } from '@ngxs/store';
import { RepositoryFactory } from '../../data';
import { takeUntil } from 'rxjs/operators';
import { DtoUtils, UseCaseStateName } from '../../core/utils';
import { StripeStateActions } from '../../core/state/stripe';
import { StripeSubscriptionCreateUseCase } from '../../core/use-cases/stripe';
import { StripeSubscriptionCreateModel } from '../../core/state/stripe';

export class StripeSubscriptionCreateUseCaseApp
  extends UseCaseAppBase<StripeSubscriptionCreateModel>
  implements StripeSubscriptionCreateUseCase {

  public static Name = 'StripeSubscriptionCreate';

  private stripeRepository: StripeRepository;

  constructor(store: Store) {
    super(store);
    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.stripeRepository.subscriptionCreateResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {
                localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.Message',
                params: DtoUtils.getMessage(response),
              },
              { localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.Header' },
              { localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.SubHeader' });
          } else {
            this.dispatch(new StripeStateActions.SubscriptionCreateCompleted({
              subscriptionId: response.dto.SubscriptionId,
              clientSecret: response.dto.ClientSecret,
              clientSecretType: response.dto.ClientSecretType,
              couponId: response.dto.CouponId,
              customerId: response.dto.CustomerId,
              planId: response.dto.PlanId,
              planPrice: response.dto.PlanPrice,
            }));
            this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.StripeSubscriptionCreate.Success');
          }
        },
      });
  }

  public override getName(): string {
    return StripeSubscriptionCreateUseCaseApp.Name;
  }

  public override init(): void {
    this.dispatchInitWithMessage('UseCase.StripeSubscriptionCreate.Initialized');
  }


  protected override onExecuteRun(params?: StripeSubscriptionCreateModel) {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.StripeSubscriptionCreate.Working');
    if (!params) {
      this.dispatchSetFailureWithAlert(
        { localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.Message', params: 'The request is undefined' },
        { localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.Header' },
        { localizationKey: 'UseCase.StripeSubscriptionCreate.Failure.SubHeader' });
    } else {
      this.dispatch(new StripeStateActions.SubscriptionCreateRequested());
      this.stripeRepository.subscriptionCreate({
        AccessKey: params.accessKey,
        CustomerId: params.customerId,
        FriendCode: params.friendCode,
      });
    }
  }

  override onDispose() {
    this.stripeRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.StripeSubscriptionCreate.Disposed');
  }
}
