import {Store} from '@ngxs/store';
import {takeUntil} from 'rxjs/operators';

import {StripeSetupIntentGetModel} from "../../core/state/stripe";
import {StripeRepository} from "../../core/repositories";

import {UseCaseAppBase} from "../use-case-app-base.class";
import {RepositoryFactory} from "../../data";
import {DtoUtils, UseCaseStateName} from "../../core/utils";
import {StripeStateActions} from "../../core/state/stripe/stripe-state-actions";
import {StripeSetupIntentGetUseCase} from "../../core/use-cases/stripe";

export class StripeSetupIntentGetUseCaseApp
  extends UseCaseAppBase<StripeSetupIntentGetModel>
  implements StripeSetupIntentGetUseCase {

  public static Name = 'SetupIntentGet';
  private stripeRepository: StripeRepository;

  constructor(store: Store) {
    super(store);

    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.stripeRepository.setupIntentGetResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {
                localizationKey: "UseCase.SetupIntentGet.Failure.Message",
                params: DtoUtils.getMessage(response)
              },
              {localizationKey: "UseCase.SetupIntentGet.Failure.Header"},
              {localizationKey: "UseCase.SetupIntentGet.Failure.SubHeader"});
          } else {
            this.dispatch(new StripeStateActions.SetupIntentGetCompleted({
              paymentMethod: response.dto.PaymentMethodId,
              setupIntent: '',
              setupIntentClientSecret:response.dto.ClientSecret,
              setupIntentStatus: response.dto.Status,

            }));
            this.dispatchSetStateWithNone(UseCaseStateName.success, "UseCase.SetupIntentGet.Success");
          }
        }
      });
  }

  override getName(): string {
    return StripeSetupIntentGetUseCaseApp.Name;
  }

  override init(): void {
    this.dispatchInitWithMessage("UseCase.StripeGetSetupIntent.Initialized");
  }

  override onExecuteRun(params?: StripeSetupIntentGetModel) {

    if(!params){
      return;
    }

    this.dispatchSetStateWithNone(UseCaseStateName.working, "UseCase.StripeGetSetupIntent.Working");
    this.dispatch(new StripeStateActions.SetupIntentGetRequested());
    this.stripeRepository.setupIntentGet({CustomerId: params.customerId})
  }

  override onDispose() {
    this.stripeRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.StripeGetSetupIntent.Disposed');
  }
}
