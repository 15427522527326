import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripePaymentIntentConfirmUseCase } from '../../core/use-cases/stripe';
import { Store } from '@ngxs/store';
import { UseCaseStateName } from '../../core/utils';
import { StripeStateActions } from '../../core/state/stripe';
import { StripeRepository } from '../../core/repositories';
import { RepositoryFactory } from '../../data';
import { StripePaymentIntentConfirmModel } from '../../core/state/stripe';
import { takeUntil } from 'rxjs/operators';

export class StripePaymentIntentConfirmUseCaseApp extends UseCaseAppBase<StripePaymentIntentConfirmModel>
  implements StripePaymentIntentConfirmUseCase {

  public static Name = 'StripePaymentConfirm';
  private stripeRepository: StripeRepository;

  constructor(store: Store) {
    super(store);

    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.stripeRepository.paymentConfirmResponse$().pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.dispatch(new StripeStateActions.PaymentIntentConfirmCompleted({
            paymentIntent: res.dto?.error?.payment_intent.id ?? '',
            paymentIntentClientSecret: res.dto?.error?.payment_intent.client_secret ?? '',
            paymentIntentStatus: res.dto?.error?.code ?? 'unspecified_error',
          }));
        }
      });
    // the response is not available because the call redirects to an url
  }

  public override getName(): string {
    return StripePaymentIntentConfirmUseCaseApp.Name;
  }

  public override init(): void {
    this.dispatchInitWithMessage('UseCase.StripePaymentConfirm.Initialized');
  }

  protected override onExecuteRun(params?: StripePaymentIntentConfirmModel) {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.StripePaymentConfirm.Working');
    if (!params) {
      this.dispatchSetFailureWithAlert(
        { localizationKey: 'UseCase.StripePaymentConfirm.Failure.Message', params: 'The request is undefined' },
        { localizationKey: 'UseCase.StripePaymentConfirm.Failure.Header' },
        { localizationKey: 'UseCase.StripePaymentConfirm.Failure.SubHeader' });
    } else {
      this.dispatch(new StripeStateActions.PaymentIntentConfirmRequested());
      this.stripeRepository.paymentConfirm(params.stripeElements, params.returnUrl, params.clientSecret);
    }
  }

  override onDispose() {
    this.stripeRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.StripePaymentConfirm.Disposed');
  }
}
