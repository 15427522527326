export const AppRoutes = {
  dashboard: '/main/dashboard',
  knowledge: '/main/knowledge',
  bookmarks: '/main/bookmarks',
  myDrafts: '/main/my-drafts',
  library: '/main/library',
  tutorials: '/main/tutorials',
  userProfile: '/main/user',
  uokDetail: '/main/uok-detail',
  beAPro: '/main/be-a-pro',
  updatePayment: '/main/update-payment',
  signIn: '/signin',
  signUp: '/signup',
  linkEditor: '/main/edit-links',
  forgotPassword: '/forgot-password',
  search: '/main/search',
}
