import { StripeCustomerGetUseCase } from '../../core/use-cases/stripe';
import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripeRepository } from '../../core/repositories';
import { Store } from '@ngxs/store';
import { RepositoryFactory } from '../../data';
import { DtoUtils, UseCaseStateName } from '../../core/utils';
import { StripeStateActions } from '../../core/state/stripe';
import { takeUntil } from 'rxjs/operators';

export class StripeCustomerGetUseCaseApp
  extends UseCaseAppBase<{ email: string }>
  implements StripeCustomerGetUseCase {

  public static Name = 'StripeCustomerGetUseCase';
  private stripeRepository: StripeRepository;

  constructor(store: Store) {
    super(store);

    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.stripeRepository.customerGetResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              {
                localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.Message',
                params: DtoUtils.getMessage(response),
              },
              { localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.Header' },
              { localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.SubHeader' });
          } else {
            this.dispatch(new StripeStateActions.CustomerGetCompleted({
              customerId: response.dto.CustomerId,
              planId: response.dto.PlanId,
              planPrice: response.dto.PlanPrice,
            }));
            this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.StripeCustomerGetUseCase.Success');
          }
        },
      });
  }

  public override getName(): string {
    return StripeCustomerGetUseCaseApp.Name;
  }

  public override init(): void {
    this.dispatchInitWithMessage('UseCase.StripeCustomerGetUseCase.Initialized');
  }

  protected override onExecuteRun(params?: { email: string }) {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.StripeCustomerGetUseCase.Working');
    if (!params) {
      this.dispatchSetFailureWithAlert(
        { localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.Message', params: 'The request is undefined' },
        { localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.Header' },
        { localizationKey: 'UseCase.StripeCustomerGetUseCase.Failure.SubHeader' });
    } else {
      this.dispatch(new StripeStateActions.CustomerGetRequested());
      this.stripeRepository.customerGet({
        Email: params.email,
      });
    }

  }

  override onDispose() {
    this.stripeRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.StripeCustomerGetUseCase.Disposed');
  }
}
