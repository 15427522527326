import { UseCaseAppBase } from '../use-case-app-base.class';
import { StripeSetupIntentConfirmUseCase } from '../../core/use-cases/stripe';
import { StripeRepository } from '../../core/repositories';
import { Store } from '@ngxs/store';
import { RepositoryFactory } from '../../data';
import { UseCaseStateName } from '../../core/utils';
import { StripeStateActions } from '../../core/state/stripe';
import { StripeSetupIntentConfirmModel } from '../../core/state/stripe';
import { takeUntil } from 'rxjs/operators';

export class StripeSetupIntentConfirmUseCaseApp
  extends UseCaseAppBase<StripeSetupIntentConfirmModel>
  implements StripeSetupIntentConfirmUseCase {
  public static Name = 'StripeSetupIntentConfirm';
  private stripeRepository: StripeRepository;

  constructor(store: Store) {
    super(store);

    this.stripeRepository = RepositoryFactory.GetStripeRepository();

    this.stripeRepository.setupIntentConfirmResponse$().pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        if (res) {
          this.dispatch(new StripeStateActions.SetupIntentConfirmCompleted({
            setupIntent: res.dto?.error?.setup_intent.id ?? '',
            setupIntentClientSecret: res.dto?.error?.setup_intent.client_secret ?? '',
            setupIntentStatus: res.dto?.error?.code ?? 'unspecified_error',
            paymentMethod: res.dto?.error?.payment_method?.id ?? '',
          }));
        }
      });
  }

  override getName(): string {
    return StripeSetupIntentConfirmUseCaseApp.Name;
  }

  override init(): void {
    this.dispatchInitWithMessage('UseCase.StripeSetupIntentConfirm.Initialized');
  }

  override onExecuteRun(params?: StripeSetupIntentConfirmModel) {

    if (!params) {
      return;
    }

    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.StripeSetupIntentConfirm.Working');
    this.dispatch(new StripeStateActions.SetupIntentConfirmRequested());
    this.stripeRepository.setupIntentConfirm(params.stripeElements, params.returnUrl, params.clientSecret);

  }

  override onDispose() {
    this.stripeRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.StripeSetupIntentConfirm.Disposed');
  }
}
