import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { AccessKeyRepository } from '../../../core/repositories';
import { UserAccessKeysActions } from '../../../core/state/user/actions';
import { UserAccessKeyModel } from '../../../core/state/user/models';
import { UserGetAccessKeysUseCase } from '../../../core/use-cases/user';
import { DtoUtils, UseCaseStateName } from '../../../core/utils';
import { RepositoryFactory } from '../../../data';
import { AccessKeyMapper } from '../../../data/access-key-repository';
import { UseCaseAppBase } from '../../use-case-app-base.class';

export class UserGetAccessKeysUseCaseApp extends UseCaseAppBase<null> implements UserGetAccessKeysUseCase {
  public static readonly Name: string = 'UserLoadAccessKeys';
  private akMapper = new AccessKeyMapper();
  private akRepository: AccessKeyRepository;

  constructor(store: Store) {
    super(store);

    this.akRepository = RepositoryFactory.GetAccessKeyRepository();

    this.akRepository.getAccessKeysStateResponse$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: response => {
          if (response.dto === null || response.dto === undefined) {
            this.dispatchSetFailureWithAlert(
              { localizationKey: 'UseCase.UserLoadAccessKeys.Failure.Message', params: DtoUtils.getMessage(response) },
              { localizationKey: 'UseCase.UserLoadAccessKeys.Failure.Header' },
              { localizationKey: 'UseCase.UserLoadAccessKeys.Failure.SubHeader' });
          } else {
            const mapped: UserAccessKeyModel[] = [];
            response.dto.forEach(ak => {
              mapped.push(this.akMapper.mapFrom(ak));
            });
            this.dispatch(new UserAccessKeysActions.GetAccessKeysModelSucceded(mapped));
            this.dispatchSetStateWithNone(UseCaseStateName.success, 'UseCase.UserLoadAccessKeys.Success');
          }
        },
      });
  }

  override onExecuteRun(_params?: null): void {
    this.dispatchSetStateWithNone(UseCaseStateName.working, 'UseCase.UserLoadAccessKeys.Working');
    this.akRepository.getAccessKeys();
  }

  override init(): void {
    this.dispatchInitWithMessage('UseCase.UserLoadAccessKeys.Initialized');
  }

  override getName(): string {
    return UserGetAccessKeysUseCaseApp.Name;
  }

  override onDispose() {
    this.akRepository.dispose();
    this.dispatchSetStateWithNone(UseCaseStateName.disposed, 'UseCase.UokLinksSave.Disposed');
  }
}
